<template>
  <div id="cards" class="cardClass" >
    <div class="service" v-intersection>
      <h3>Entdecken Sie unsere Vorteile</h3>
    </div>
    <div class="card-outer">
      <div class="card" v-intersection>
        <div class="kasten-txt">
          <div>
          <img class="kasten-icon" src="../assets/beratung-min.jpg" alt=""  loading="eager"/></div>
          <h2>Beratung</h2>
          <br>
          <p>
            Sie haben eine grobe Vorstellung wie Ihr Neu- oder Altbau nach der
            Renovierung oder Sanierung aussehen soll? Sie wünschen eine Wand
            einzuziehen, separate Küche und Wohnzimmer zu einem Einladend
            offenen Bereich umzugestalten? Sie sind sich in der Auswahl der
            Materialien und des Designs unsicher und vertrauen lieber auf
            Expertise und technisches Know-How? Wir beraten Sie gerne in allen
            Fragen rund um Ihr individuelles Bauprojekt!
          </p>
        </div>
      </div>
      <div class="card" v-intersection>
        <div class="kasten-txt">
          <div>
          <img class="kasten-icon" src="../assets/planung-min.jpg" alt="" loading="eager"/></div>
          <h2>Planung</h2>
          <br>
          <p>
            Nachdem wir mit Ihnen gemeinsam das perfekte Design gefunden haben,
            machen wir uns an die Arbeit. Soll beispielsweise eine
            Komplett-Sanierung stattfinden und Sie wünschen bestimmte Räume
            während der Bauphase weiterhin zu nutzen, planen wir mit Ihnen
            gemeinsam den konkreten Ablauf, so dass Ihr Projekt nicht zum
            Störfaktor wird.
          </p>
        </div>
      </div>
      <div class="card" v-intersection>
        <div class="kasten-txt">
          <div>
          <img class="kasten-icon" src="../assets/durchf-min.jpg" alt="" width="400px" loading="eager"/></div>
          <h2>Durchführung</h2>
          <br>
          <p>
            Als Profi in Sachen Innenausbau setzen wir uns an die Durchführung
            Ihres Bauvorhabens. Wir arbeiten sauber und mit hochwertigen
            Materialien. Von der Anfahrt des Materials, über die Demontage Ihrer
            alten Möbel und Geräte und die Durchführung der Baumaßnahmen, bis
            zur Reinigung der Baustelle, können Sie auf uns zählen. Ihnen fällt
            erst während des Projekts auf, dass Sie weitere Umbaumaßnahmen
            wünschen? Flexibel reagieren wir auf Ihre Wünsche und planen die
            Arbeiten in das Projekt ein.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cards",
};
</script>

<style scoped>
/* BIG SCREEN SIZE */
.service{
  display:none;
}
   .cardClass {
    background-image: linear-gradient(hsla(240, 7%, 89%, 0.6), #fff);
    padding-top: 25%;
    padding-bottom: 10%;
    gap: 40px;
  }
  .card-outer {
    display: flex;
    width: 70%;
    margin: auto;
  }
  .card {
    width: 40%;
    height: 500px;
    transition: 0.3s;
    padding: 10px;
    margin: auto;
  }

  .kasten-icon {
    width: 100%;
  }
  .kasten-txt {
    width: 73%;
    font-size: 15px;
    margin: 0 auto;
    text-align: left;
  }
  .kasten-txt > h2 {
    margin-left: 15%;
  }

/* LAPTOP(SMALLER SCREEN) */
@media only screen and (max-width: 1600px){
  .cardClass {
    padding-bottom: 10%;
    gap: 40px;
  }
  .card-outer {
    display: flex;
    width: 75%;
    margin: auto;
  }
  .card {
    width: 40%;
    height: 500px;
    transition: 0.3s;
    padding: 15px;
    margin: auto;

  }
  .kasten-icon {
    width: 100%;
  }
  .kasten-txt {
    width: 73%;
    font-size: 15px;
    line-height: 1.5;
    font-weight:400;
    margin: 0 auto;
    text-align: left;
  }
  .kasten-txt > h2 {
    margin-left: 16%;
  }
}
@media only screen and (max-width: 1298px){
.cardClass {
    padding-bottom: 10%;
    gap: 40px;
  }
  .card-outer {
    display: flex;
    width: 75%;
    margin: auto;
  }
  .card {
    width: 43%;
    max-height: 500px;
    transition: 0.3s;
    padding: 15px;
    margin: auto;

  }
  .kasten-icon {
    width: 100%;
  }
  .kasten-txt {
    width: 73%;
    font-size: 13px;
    line-height: 1.5;
    font-weight:400;
    margin: 0 auto;
    text-align: left;
  }
  .kasten-txt > h2 {
    margin-left: 16%;
  }

}
/* MOBILE */
@media only screen and (max-width: 578px){

  #cards{
    margin-top:70%;
  }
  .card-outer{
    display:flex;
    flex-wrap:wrap;
    width: 90%;
    padding-top:15%;
  }
  .card {
    width: 90%;
    height: 50%;
    transition: 0.3s;
    padding: 15px;
    margin: 15px;
    border-top:1px solid lightgray;
    padding-top:28px;
    padding-bottom:40px;

  }
  .kasten-icon {
    width: 100%;

  }
  .kasten-txt {
    display:flex;
    flex-wrap: wrap;
    width: 100%;
    font-size: 15px;
    text-align: left;

  }

  .kasten-txt h2{
   margin:0 auto;
   padding-top:10%;

  }
.service{
  padding-top:10%;
  display:block;
  width:70%;
  margin:0 auto;

}
.service>h3{
  font-size:23px;
}

}



</style>
