import Vue from 'vue'
import App from './App.vue'
import VueLazy from 'vue-lazy'
import slideAnimation from './directives/slideAnimation'
import sideSlideInAnimation from './directives/sideSlideAnimation'
import rightObserver from './directives/rightObserver'
import router from './router/router'


Vue.config.productionTip = false
Vue.use(VueLazy)
// Vue.use(router)

//register directive
Vue.directive('intersection',slideAnimation)
Vue.directive('slideInAnimation',sideSlideInAnimation)
Vue.directive('appear',rightObserver)

new Vue({
  render: h => h(App),
}).$mount('#app')
