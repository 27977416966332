<template>
<div id="footer">
     <div class="footer-content">
        <div class="footer-kontakt">
            <h2 class="logo-text">Kontakt</h2>
            <p>
                Geschäftsführer: Stevo Nasti&#263; <br>
                Adresse: Waldstraße 1, <br>60528 Frankfurt am Main <br>
                E-mail: info@schoenhaus-bau.de <br>
                Mobil: 0178 - 86 20 580 <br>
                Telefon: 069 - 955 189 52
            </p>
        </div>
        <div class="footer-links">
                <!-- <li><a href="datenschutz.php">Datenschutz|</a></li>
                <li><a href="impressum.php"> Impressum</a></li> -->

        </div>
            <div class="social-media">
                <h2 class="logo-text" >Follow us:</h2>
                <a href="https://m.facebook.com/schoenhausbau/?ref=bookmarks" class="fa fa-facebook"></a>
                <a href="https://instagram.com/schoenhaus_bau?utm_medium=copy_link" class="fa fa-instagram"></a>
            </div>
                <div class="link">
                <a class="dsLink" href='#Datenschutz' v-bind:class="{showDS:dsLink}" @click="showDS = !showDS; openDS()">Datenschutz</a> | 
                <a class="imprLink" href='#Impressum'  v-bind:class="{showImpr:imprLink}" @click="showImpr = !showImpr; openImpr()" >Impressum</a> 
                </div>

    </div>
        <div class="footer-bottom">
            &copy; Schoenhaus-Bau GmbH 2023
        </div>

        <Datenschutz id="Datenschutz" v-if="this.showDS"></Datenschutz>
        <Impressum id="Impressum" v-if="this.showImpr" ></Impressum>

</div>
</template>

<script>
import Datenschutz from './Datenschutz.vue';
import Impressum from './Impressum.vue';

export default {
    components: {
    Datenschutz,
    Impressum
},
    data: () => {
        return {
            showDS: false,
            showImpr:false
        }
    },
    methods : {
        //open impressum & close datenschutz
        openImpr() {
            document.getElementById('Datenschutz').classList.add('closeDS');
        },
        openDS(){
            document.getElementById('Impressum').classList.add('closeImpr');
        }
            
        
    }
}
</script>

<style scoped>
.closeDS,.closeImpr{
    display:none;
}

.dsLink, .imprLink{
    width:45%;
    color:white;
    text-decoration: none;
}


#footer {
    background: rgb(4, 4, 63);
    color: #d3d3d3;
    height: 450px;
    font-family: verdana;
    margin-left:0px;
    margin-right:0px;

}

.footer-content{
    height: 400px;
    display: flex;
}

.footer-kontakt{
        margin-top:2%;
        margin-left: 5%;
        flex: 1;
        font-size:18px;
        line-height: 2;
}


.footer-bottom{
    /*background: #343a40;*/
    background: rgb(12, 12, 80);
    color: #686868;
    height: 60px;
    text-align: center;
    bottom: 0px;
    left: 0px;
    margin-top:15px;
    padding-top: 20px;
}

.social-media{
    height:200px;
    font-size:18px;
    line-height: 2;
    margin-top: 2.5%;
    flex:1;
}
.social-media a{
    width: 30px;
    font-size: 25px;
    margin-left:7%;
    margin-top:2%;
    color: white;
    text-decoration: none;
}

.logo-text{
    color:#d3d3d3;
}
.link{
    display:flex;
    font-size:18px;
    line-height: 2;
    margin-top: 5%;
    flex:1;
}
.link a{
    width:25%;
    font-size: 15px;
    color: white;
    text-decoration: none;
}

/*MEDIA QUERIES*/
@media screen and (max-width: 991px) {

    .footer-content{
        display:flex;
        flex-wrap: wrap;
        flex-direction: column;
    }
    .footer-kontakt{
        font-size: 18px;
        margin:0 auto;
        display:block;
        text-align: justify;
        margin-top: 5%;

    }
    .footer-bottom{
        height:100px;
        width:100%;
    }
   .footer-links {
       display:none;
   }
    .social-media{
       text-align: center;
   }

   @media only screen and (max-width: 1298px){
    .footer-content{
        display:flex;
        flex-wrap: wrap;
        flex-direction: column;
    }
    .footer-kontakt{
        font-size: 13px;
        margin:0 auto;
        display:block;
        text-align: justify;
        margin-top: 5%;

    }
    .footer-bottom{
        height:100px;
        width:100%;
    }
   .footer-links {
       display:none;
   }
    .social-media{
       text-align: center;
   }
   .link a{
    font-size: 13px;
    margin-left:7%;
    margin-top:5%;
    color: white;
    text-decoration: none;
}

}
   /* MOBILE */
@media screen and (max-width: 578px){
    .footer-kontakt{
        display:none;
    }
    .footer-bottom{
        height:50px;

    }
    .social-media{
       text-align: center;
   }
   .social-media{
    height:200px;
    font-size:18px;
    line-height: 2;
    margin-top: 2.5%;
    flex:1;
}
.social-media a{
    width: 35px;
    font-size: 35px;
    color:white;
    margin-left:7%;
    margin-top:2%;
    text-decoration:none;
}
.social-media a:hover{
    color: #0088a9
}
.logo-text{
    color:#d3d3d3;
}
.dsLink, .imprLink{
    color:white;
    text-decoration: none;
}
.link{
    margin-left:15%;
    display:flex;
    width:70%;
    height:300px;
    font-size:15px;

}
.link a{
    margin:0 auto;
    font-size: 15px;
    color: white;
    text-decoration: none;
}
}
}


</style>