import { render, staticRenderFns } from "./SanRenComp.vue?vue&type=template&id=804da052&scoped=true&"
import script from "./SanRenComp.vue?vue&type=script&lang=js&"
export * from "./SanRenComp.vue?vue&type=script&lang=js&"
import style0 from "./SanRenComp.vue?vue&type=style&index=0&id=804da052&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "804da052",
  null
  
)

export default component.exports