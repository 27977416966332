<template>
    <div class="impressum" id="impressum">
        <h1>Impressum</h1><br><br>
        <h2>Angaben gemäß §5 TMG</h2>

        <p>
            Schönhaus Bau GmbH
            <br>
            Waldstraße 1,
            <br>
            60528 Frankfurt am Main
        </p>
        <br>
        <p>
            Handelsregister: 124996
            <br>
            Registergericht: Amtsgericht Frankfurt am Main
            <br>
            USt-idNr.: DE344928080
        </p>
        <br>

            <h5>Vertereten durch den Geschäftsführer:</h5>
            <h6>Stevo Nastic</h6>

        <br>
        <h2>Kontakt</h2>
        <p>
            Mobil: 0178 - 86 20 580 <br>
            Telefon: 069 - 955 189 52
            <br>
            E-Mail: info@schoenhaus-bau.de
        </p>
    </div>
</template>

<script>
export default{
    name: 'Impressum'
}
</script>

<style scoped>
.impressum{
    color:rgb(4, 4, 63);
    padding-top:10%;
    padding-bottom:10%;
    width: 50%;
    text-align: left;
    margin-left:25%;
}

@media only screen and (max-width: 1298px){
    .impressum{
        color:rgb(4, 4, 63);
        font-size:13px;
    }
}
</style>