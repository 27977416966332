<template>
  <div id="app">
     <nav-bar></nav-bar>
      <home-component></home-component>
      <info-cards id="cards"></info-cards>
      <san-ren-comp></san-ren-comp>
      <modern></modern>
      <neubau></neubau>
      <brand></brand>
      <facilitiy></facilitiy>
      <kontakt></kontakt>
      <btm></btm>
      <button v-on:click="topFunction()" id="myBtn" title="Go to top" v-bind:class="{displayBtn:scrollPos}">&#8679;</button>
      <a href="tel:+491788620580" id="phoneBtn" title="Go to top" v-bind:class="{displayBtn:scrollPos}">	&#9743;</a>

  </div>
</template>

<script>
import NavBar from './components/NavBar.vue';
import HomeComponent from './components/HomeComponent.vue';
import InfoCards from './components/InfoCards.vue';
import SanRenComp from './components/SanRenComp.vue';
import Modern from './components/Modernisierung.vue'
import Neubau from './components/NeuBau.vue'
import Brand from './components/Brandschutz.vue'
import Facilitiy from './components/Facility.vue'
import Kontakt from './components/Kontakt.vue'
import Impressum from './components/Impressum.vue'
import btm from './components/Bottom.vue'
import Datenschutz from './components/Datenschutz.vue'


export default {
  name: 'App',
  components: {
    NavBar,
    HomeComponent,
    InfoCards,
    SanRenComp,
    Modern,
    Neubau,
    Brand,
    Facilitiy,
    Kontakt,
    Impressum,
    btm,
    Datenschutz
},
  data: () => {
    return {
      scrollPos: null
    }
  },
  methods: {
    showBtn() {
      this.scrollPos = window.scrollY
    },
    topFunction() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0 //chrome etc.
    }
  },
  mounted() {
    window.addEventListener('scroll', this.showBtn);
    console.log("mounted");
    }
  }

</script>

<style>
  #myBtn{
    font-size: 30px;
  }
  #phoneBtn{
    display:none
  }
h1,h2,h3,h4{
  font-weight: 300;
}
body{
    margin-left:0px;
    margin-right:0px;
}

span{
  color:rgb(4, 4, 63);
}
.displayBtn {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: rgba(255, 255, 255, 0.7);
  border:1px solid rgb(4, 4, 63);
  color: rgb(4, 4, 63);
  cursor: pointer;
  padding: 15px;
  border-radius: 10px;
  font-size: 18px;
}

.displayBtn:hover {
  background-color: #555; /* Add a dark-grey background on hover */
}
#app {
  font-family: Quicksand, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  line-height: 1.5;

}

#cards{
  margin-top:45%;
}

 .before-enter{
  opacity: 0;
  transform: translateY(20%);
}
.enter{
  opacity: 1;
  transform: translateY(-10%);
  transition: all 0.7s ease-out;
}

.before-appear{
  opacity: 0;
  transition: all 1s ease-out;
}
.appear{
  opacity: 1;
}

/*SLIDE ANIMATION OBSERVER */
.before-slide{
    opacity: 0;

}
.slide{
    opacity: 1;
    transform: translateX(-100%);
    animation: slide 2s forwards;
}
@keyframes slide{
    100%{transform: translateX(0%);}
}


/* lapotop */
@media only screen and (max-width: 1600px){
    #cards{
    margin-top:40%;
  }
}

/* MOBILE */
@media only screen and (max-width: 578px){
  #phoneBtn{
    display:block;
    font-size:25px;
  }
  #myBtn{
    display:none;
  }
  h3{
    text-align: center;
    font-size:25px;
  }
  body{
    margin-left:0px;
    margin-right:0px;
  }
  #app{
    font-size:15px;
  }


}
</style>
