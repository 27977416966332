<template>
    <div class="start-text-two" v-slideInAnimation>
      <div class="content">
        <div class="einleitung">
        <h2>Was uns wichtig ist!</h2>
        </div>
            <p>Professionelles Arbeiten auf neuestem technologischen Stand,
                eine Bauweise mit hochwertigen und nachhaltigen Materialien und unser besonderes Augenmerk bei der Qualitätsüberwachung sind maßgeblich für Schönhaus Bau.
                Wir stehen für grundsolide Handwerksqualität, Erfahrung,
                  Zuverlässigkeit und Wirtschaftlichkeit.</p>
              </div>
            </div>
</template>

<script>
export default {
    name: 'start-text-two'
}
</script>

<style scoped>
.start-text-two{
    /*min-height: 100%;*/
    margin-right:30%;
    margin-top:15%;
    text-align: left;
    position:absolute;
    padding:13px;
    width: 45%;
    height: 45%;
    font-size:22px;
    background-color:  rgba(255, 255, 255, 0.7);

}
.einleitung{
  padding-left:5%;
  font-size:20px;
  border:4px solid rgb(4, 4, 63);
}

.fadeInTwo-enter-active,
.fadeInTwo-leave-active{
    transition: all 0.5s linear;
}

.fadeInTwo-enter-from {
  opacity: 0;
}
.fadeInTwo-enter-to {
  opacity:1;
}
/* lapotop */
@media only screen and (max-width: 1600px){

.einleitung{
  font-size:17px;
  border:4px solid rgb(4, 4, 63);

}
.start-text-two{
    /*min-height: 100%;*/
    margin-right:30%;
    margin-top:15%;
    text-align: left;
    position:absolute;
    padding:13px;
    width: 45%;
    height: 45%;
    font-size:17px;
    background-color:  rgba(255, 255, 255, 0.7);

}
}
@media only screen and (max-width: 578px){
  .start-text-two{
    width:100%;
    max-height: 37.4%;
    margin:0 auto;
    top:10%;
    font-size:13px;
    text-align: left;
  }
  .content{
    width:90%;
    margin:0 auto;

  }
  .einleitung{
    padding-left:0%;
    border:none;
  }
}

</style>