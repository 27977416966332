<template>
<div id="modern" class="modernisierung">
    <div class="modernisierung-text" v-slideInAnimation>
        <div class="mod-content">
            <h3>Modernisierung heißt Aufwertung</h3> <br>
            <p>Während wir für Sie renovieren und sanieren finden häufig bereits Modernisierungarbeiten statt. So zum Beispiel,
                wenn wir Ihre Immobilie mit einer neuen Dämmung ausstatten,
                Wärmeschutzfenster eingebaut, oder neue und moderne Heizkörper angeschafft werden.
                Darüber hinaus können Sie aber auch erneuerbare Energien einsetzen oder Sie funktionieren Ihr zu Hause zum Smart-Home um.
                        Setzen Sie bei Ihrer Immobilie früh genug auf eine energetische Modernisierung wie beispielsweise das Anbringen
                        einer Wärmedämmung an die Fassade, die Dämmung der obersten Geschossdecke/des Daches, eine Dämmung der Kellerdecke,
                        den Austausch von alten Holzfenstern gegen neue Isolierglasfenster, den Austausch des alten Heizkessels gegen einen neuen Heizkessel mit besserem
                        Wirkungsgrad oder den Einbau von Energiesparlampen (u. W.), um Endenergie nachhaltig einzusparen.
                        Außerdem reduzieren wir durch den Einbau von Kaltwasserzählern und Warmwasserzählern,
                        oder auch durch Regenwassergewinnungsanlagen zur Einsparung von Brauchwasser Ihren Wasserverbrauch nachhaltig.
                    </p>
            </div>
</div>
</div>
</template>

<script>


export default {

    name: 'Modern',

     setup() {

    },
}
</script>

<style scoped>
.modernisierung{
    display:flex;


}
.modernisierung-text{
    padding-top:13%;
    padding-bottom:10%;
    text-align: left;
    display:flex;
    justify-content: space-between;


}
.mod-content{
    width:50%;
    margin-left:20%;

}

h3{
    font-size:35px;
    color: #2c3e50;
}
img{
    width:45%;
    height:30%;
    padding:5px;
}
/* LAPTOP VIEW */
@media only screen and (max-width: 1600px) {
    .modernisierung-text{
        margin-top:5%;
        padding-bottom:10%;
        text-align: left;

}
.mod-content{
    width:60%;

}

}
@media only screen and (max-width: 1298px){
    .modernisierung-text{
    margin-top:5%;
    padding-bottom:8%;
    text-align: left;
    font-size:15px;

}


}
/* MOBILE */
@media only screen and (max-width: 578px){
    .modernisierung-pics{
        display:none;
    }
h3{
    font-size:30px;
}
.modernisierung{
    padding-top:8%;
}
    .modernisierung-text{
        margin-left:0%;
        padding-left:0%;
        padding-top:15%;
        padding-bottom:10%;
        width:90%;
        margin:0 auto;
        text-align: left;
        font-size:15px;

    }
    .modernisierung-pics{
        display:none;
    }
    .mod-content{
        width:100%;
        margin-left:0%;
    }
}




</style>