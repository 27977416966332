<template>
<div class="start-con">
<div class="start-text" v-slideInAnimation>
  <div class="einleitung">
    <div class="title">
        <h2 >Lernen Sie uns kennen!</h2>
      </div>
            <p>Mit SCHÖNHAUS BAU  erhalten Sie Ihren zuverlässigen Partner rund um das Thema Bau.
                Unter dem Motto #ALLESAUSEINERHAND  begleiten wir Sie von der <span>Beratung & Planung</span>  über die <span>Durchführung</span>  bis zur Fertigstellung Ihres persönlichen Bauprojekts,
                ob <span>Renovierung</span>, <span>Sanierung</span>, <span>TÜV zertifizierten Brandschutz</span>,
                <span>Facilitiy Management</span>  oder Ihren <span>Neubau</span>, wir sind für Sie da.</p>
              </div>
</div>
<div class="time" v-intersection>
  <div class="time-content">
        <h2 >Öffnungszeiten</h2>
        <p>Montag     08:00–19:00</p>
        <p>Dienstag	  08:00–19:00</p>
        <p>Mittwoch	  08:00–19:00</p>
        <p>Donnerstag	08:00–19:00</p>
        <p>Freitag  	08:00–19:00</p>
        <p>Samstag   	08:00–16:00</p>
        <span><p class="angebot">Besprechen Sie Ihr Angebot unverbindlich <a href="#kontakt">&#9993;</a></p></span>
        <p class="numbers">
          Telefon: 069 - 955 189 52 <br>
          Mobil: 0178 - 86 20 580 
        </p>
      </div>
</div>
</div>
</template>

<script>

export default {
    name: 'home-text',

}

</script>

<style scoped>
  a{
    text-decoration:none;
  }


.start-con{
  top:15%;
  display:flex;
  position:absolute;
  width:99.9%;
  height:80%;
}
.start-text{
    /*min-height: 100%;*/
    left:0;
    margin-top:10%;
    text-align: left;
    background-color:  rgba(255, 255, 255, 0.7);
    padding:13px;
    width: 50%;
    height: 65%;
    font-size:22px;
   /* border: 1px solid white;*/

}
.einleitung{
  font-size:20px;
  width:50%;
  margin-left:50%;
  padding-top:5%;
}
.title{
  border:4px solid rgb(4, 4, 63);
  padding-left:5%;
}
.angebot{
  font-size:20px;
  border-top:1px solid rgb(4, 4, 63);
}
.time{
    margin-top:12.5%;
    margin-left:5%;
    text-align: left;
    background-color:  rgba(255, 255, 255, 0.7);
    padding:13px;
    width: 20%;
    height: 70%;
    font-size:19px;
}

.time-content{
  padding-left:15%;
  
}
.numbers{
  font-size:15px;
  font-weight:600;
}

/*SLIDE ANIMATION OBSERVER */
.before-slide{
    opacity: 0;

}
.slide{
    opacity: 1;
    transform: translateX(-100%);
    animation: slide 2s forwards;
}
@keyframes slide{
    100%{transform: translateX(0%);}
}

.fadeIn-enter-active,
.fadeIn-leave-active{
    transition: all 0.8s ease;
}

.fadeIn-enter-from {
  opacity: 0;
}
.fadeIn-enter-to {
  opacity:1;
}

/* lapotop */
@media screen and (max-width: 1600px){

.start-text{
    /*min-height: 100%;*/
    left:0;
    margin-top:10%;
    text-align: left;
    background-color:  rgba(255, 255, 255, 0.7);
    padding:13px;
    width: 50%;
    height: 65%;
    font-size:19px;
   /* border: 1px solid white;*/

}
.einleitung{
  font-size:17px;
  width:50%;
  margin-left:50%;
  padding-top:3%;
}
.title{
  border:4px solid rgb(4, 4, 63);
  padding-left:5%;
}
.angebot{
  font-size:20px;
  border-top:1px solid rgb(4, 4, 63);
}
.time{
    margin-top:12.5%;
    margin-left:5%;
    text-align: left;
    background-color:  rgba(255, 255, 255, 0.7);
    padding:10px;
    width: 20%;
    height: 65%;
    font-size:13px;
}

.time-content{
  padding-left:15%;
}


}
@media only screen and (max-width: 1298px){
  .start-text{
    /*min-height: 100%;*/
    left:0;
    margin-top:10%;
    text-align: left;
    background-color:  rgba(255, 255, 255, 0.7);
    padding:13px;
    width: 50%;
    height: 65%;
    font-size:15px;
   /* border: 1px solid white;*/

}
.einleitung{
  font-size:15px;
  width:50%;
  margin-left:50%;
  padding-top:3%;
}
.title{
  border:4px solid rgb(4, 4, 63);
  padding-left:5%;
}
.angebot{
  font-size:15px;
  border-top:1px solid rgb(4, 4, 63);
}
.time{
    margin-top:12.5%;
    margin-left:5%;
    text-align: left;
    background-color:  rgba(255, 255, 255, 0.7);
    padding:10px;
    width: 20%;
    height: 70%;
    font-size:13px;
}

.time-content{
  padding-left:15%;

}
.numbers{
  font-size: 10px;
  
}

}

@media screen and (max-width: 578px){
  .start-con{
  top:10%;
  height:100%;
}
   .time{
    display:none;
  }
  .start-text{
    /*min-height: 100%;*/
    margin-top:0%;
    left:0;
    text-align: left;
    background-color:  rgba(255, 255, 255, 0.7);
    width: 100%;
    max-height: 37.4%;
   /* border: 1px solid white;*/

}
.einleitung{
  font-size:13px;
  width:100%;
  margin-left:0px;

}
.title{
  padding-left:0%;
  border:none;
}
}


</style>
