<template>
    <div class="carousel">
        <home-carousel-component
        v-for="(slide,index) in slides"
        :slide="slide"
        :key="`item-${index}`"
        :current-slide="currentSlide"
        :index="index"></home-carousel-component>
        <!-- Show component only on the first element in slides -->
        <home-text></home-text>

    </div>
</template>

<script>
import HomeCarouselComponent from "./HomeCarouselComponent.vue"
import HomeText from "./HomeText.vue"
import HomeTextTwo from "./HomeTextTwo.vue";

export default {
    props: ["slides"],
    components: {
        HomeCarouselComponent,
        HomeText,
        HomeTextTwo
    },
    data: () => ({
        currentSlide: 0,
        slideInterval: null
    }),
    methods: {
        setCurrentSlide(index) {
            this.currentSlide = index;
        }
    },
    mounted() {
        this.slideInterval = setInterval(() => {
            const index = this.currentSlide  <  this.slides.length -1 ? this.currentSlide + 1  : 0;
            this.setCurrentSlide(index);
        }, 10000)
    },
    beforeUnmount() {
        clearInterval(this.slideInterval);
    }
}
</script>


<style scoped>
.carousel {
    display:flex;
    justify-content: center;
    overflow:hidden;
}
@media only screen and (max-width: 578px){

}

</style>