<template>
<div id="home">
  <home-carousel :slides="slides"></home-carousel>
  </div>
</template>

<script>
// @ is an alias to /src
import HomeCarousel from '@/components/HomeCarousel.vue'



export default {
  name: 'Home',
  components: {
    HomeCarousel,

  },
  data: () => ({
      slides: [
        { url: require('../assets/san2-.jpeg')},
        { url: require('../assets/facility1-.jpeg') }
      ]
  })
}

</script>
<style scoped >
@media only screen and (max-width: 578px){
  #home{


  }
}



</style>