<template>
<div id="nav">
  <div class="nav-desk" v-bind:class="{updateNav:scrollPosition}">
    <div class="logo">
        <img class="pic" src="../assets/firmenlogo.png" alt="fimrenlogo">
      </div>
      <div class="nav-links">
            <a href="#home" class="navLink">Startseite</a> |
            <a href="#cards" class="navLink">Service</a> |
            <div class="dropdown">
            <a href="#" class="navLink">Leistungen</a> |
            <div class="dropdown-content">
                <a href="#sanieren" class="navLink">Sanieren & Renovieren</a>
                <a href="#modern" class="navLink">Modernisieren & Aufwerten</a>
                <a href="#neu" class="navLink">Neubau</a>
                <a href="#brandschutz" class="navLink">Brandschutz</a>
                <a href="#fac" class="navLink">Facility</a>
              </div>
            </div>
            <a href="#kontakt" class="navLink">Kontakt</a> 
          </div><!--LINKS END-->
        </div>
      <!-- MOBILE NAV -->
      <header class="header">
      <div class="mobile-nav" >
        <div class="left-nav-mobile">
              <a href="#start"><img src="../assets/firmenlogo.png" alt="firmenlogo" width="250px" ></a>
              </div>

          <!-- SIED NAV -->

          <div class="hamburger-menue">
            <button class="navbar-toggler hamburger-button" type="button" data-toggle="collapse" data-target="#ham-content"
            aria-controls="ham-content" aria-expanded="false" aria-label="Toggle navigation">
            <div class="animated-hamburger" v-bind:class="{open:showNav}" @click="showNav=!showNav"><span></span><span></span><span></span></div>
          </button>
          </div>

        <div class="sidenav" v-if="showNav">
                        <div class="logo-mobile-menue">
                          <a href="#start"><img src="../assets/firmenlogo.png" alt="firmenlogo" width="250px" class="mob-log" ></a>
                        </div>
                        <div class="links"><a href="#home"  @click="showNav=!showNav">Startseite</a></div>
                          <div class="links"><a href="#cards"  @click="showNav=!showNav">Service</a></div>
                          <div class="links"><a href="#sanieren"  @click="showNav=!showNav">Sanierung</a></div>
                          <div class="links"><a href="#neu"  @click="showNav=!showNav">Neubau</a></div>
                          <div class="links"><a href="#modern"  @click="showNav=!showNav">Modernisierung</a></div>
                          <div class="links"><a href="#brandschutz"  @click="showNav=!showNav">Brandschutz</a></div>
                          <div class="links"><a href="#fac"  @click="showNav=!showNav">Facility Management</a></div>
                          <div class="links"><a href="#kontakt"  @click="showNav=!showNav">Kontakt</a></div>
                          <div class="sLinks"><a href="https://m.facebook.com/schoenhausbau/?ref=bookmarks" class="fa fa-facebook"></a>
                          <a href="https://instagram.com/schoenhaus_bau?utm_medium=copy_link" class="fa fa-instagram"></a></div>
                  </div>
            </div>
        </header>
    </div>
</template>

<script>
export default {
  name: 'navbar',
     data:() => {
        return {
          showNav: false,
          scrollPosition: null,
        }
  },
  methods:{
      updateScroll(){
        this.scrollPosition = window.scrollY
      }
    },
    mounted(){
      window.addEventListener('scroll', this.updateScroll);
    }
}
</script>

<style scoped>

.header{
  display:none;
}
.nav-desk {
    /*background-image: repeating-linear-gradient(40deg, white, rgb(4, 4, 63) );*/
    background: rgba(255,255,255,0.9);
    margin:0 auto;
    margin-top:2%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    top:0;
    left:0;
    right:0;
    width:80%;
    z-index: 99999;
    min-height:90px;

}
.updateNav{
  /*background-image: repeating-linear-gradient(40deg, white, rgb(4, 4, 63) );*/
    width:100%;
    margin-top:0%;
    transition: all 0.4s cubic-bezier(0.82,0.78,0.19,0.96);
}
.nav-links{
  width:50%;
}
  .navLink {
    color:  rgb(4, 4, 63);
    margin:10px;
    text-decoration: none;
    font-size:19px;
    font-weight:bold;

  }
  .logo{
    width:50%;
  }
  .pic{
    width:40%;
  }
  /*  DROPDOWN STARTS HERE */

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: rgba(255,255,255,0.9);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #f1f1f1}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

@media only screen and (max-width: 1298px){
  .nav-desk {
    /*background-image: repeating-linear-gradient(40deg, white, rgb(4, 4, 63) );*/
    background: rgba(255,255,255,0.9);
    margin:0 auto;
    margin-top:2%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    top:0;
    left:0;
    right:0;
    width:80%;
    z-index: 99999;
    min-height:90px;

}
.updateNav{
  /*background-image: repeating-linear-gradient(40deg, white, rgb(4, 4, 63) );*/
    width:100%;
    margin-top:0%;
    transition: all 0.4s cubic-bezier(0.82,0.78,0.19,0.96);
}
.nav-links{
  width:50%;
}
  .navLink {
    color:  rgb(4, 4, 63);
    margin:10px;
    text-decoration: none;
    font-size:15px;
    font-weight:bold;

  }
  .logo{
    width:50%;
  }
  .pic{
    width:40%;
  }
  /*  DROPDOWN STARTS HERE */

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: rgba(255,255,255,0.9);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #f1f1f1}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

}

@media only screen and (max-width: 578px){
  .nav-desk{
    display:none;
  }

  .header{
      display:block;
      width:100%;
      background-color: white;
      z-index:99999;
      display:flex;
      min-height: 10%;
      top:0;
      border-bottom: 1px solid rgb(4, 4, 63);
      align-items: center;
      justify-content: space-between;
      position:fixed;
  }
  .left-nav-mobile{
    padding-left:25px;
  }

  .mobile-nav{
      width:100%;
      display:flex;
      align-items: center;
      justify-content: space-around;
  }

  .sidenav{
        text-align: center;
        display:block;
        text-decoration: none;
        margin: 0 auto;
        padding: 0;
        width: 70%;
        height:1000px;
        background-color: #fff;
        border-right: 1px solid rgb(4, 4, 63);
        left:0;
        top:0;
        position: fixed;
        transition: 3s ease-in-out;

      }
.sidenav a{
      font-size: 20px;
      color: rgb(4, 4, 63);
      text-decoration: none;
}
.links{
  margin:30px;

}
.animated-hamburger{
      width: 30px;
      height: 20px;
      position: relative;
      margin: 0px;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .5s ease-in-out;
      -moz-transition: .5s ease-in-out;
      -o-transition: .5s ease-in-out;
      transition: .5s ease-in-out;
      cursor: pointer;

    }
    .animated-hamburger span{
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
      }

    .animated-hamburger span {
      background: rgb(4, 4, 63);
    }
    .animated-hamburger span:nth-child(1) {
      top: 0px;
  }

  .animated-hamburger span:nth-child(2) {
    top: 10px;
  }

  .animated-hamburger span:nth-child(3) {
    top: 20px;
  }

  .animated-hamburger.open span:nth-child(1) {
    top: 11px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  .animated-hamburger.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
  }

  .animated-hamburger.open span:nth-child(3) {
    top: 11px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }

  .hamburger-button{
    border:none;
    background-color:white;
  }
  .mob-log{
    padding-top:20px;
  }
  .sLinks a{
    margin:8px;
    font-size:35px;
  }
}

</style>