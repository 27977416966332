const rightSlide= new IntersectionObserver(
    (entries, rightSlide) => {
        entries.forEach((entry) => {
            if(entry.isIntersecting){
                entry.target.classList.add('appear');
                console.log("intersected");
                rightSlide.unobserve(entry.target);
            }else {
                entry.target.classList.remove('appear');
            }
        });
    }
);

//export default
export default {
bind(el) {
    el.classList.add('before-appear');
    rightSlide.observe(el);
}
}