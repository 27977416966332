<template>
<div id="sanieren">
    <div class="san-bg">
        <img src="../assets/san2-.jpeg" alt="">
            <div class="sanierenText" >
                <h3 v-intersection>
                    Sanieren oder Renovieren?
                </h3> <br>
                <p v-intersection>Es wird Zeit für eine Veränderung?
                    Das Parkett benötigt eine Aufbereitung oder Sie wünschen Ihre Räume in ganz neuem Licht zu sehen?
                    Vielleicht mit abgehangenen Decken und ein paar dimmbaren Spots?
                    <span>Wir übernehmen bereits ab der Planung und beraten Sie gerne bei der Auswahl von Design und Material.</span>
                    Mit gutem Grund sind unsere Kunden zufrieden: Wir verarbeiten ausschließlich hochwertige Materialien und garantieren Ihnen als Bauherr*in,
                    durch unsere besonders sorgfältige Bauausführung, eine exzellente Qualität.
                    Ihnen bieten wir maßgeschneiderte Lösungen zu vernünftigen Preisen.<span>In sämtlichen Phasen der Planung und Realisierung legen wir von Schönhaus Bau größten Wert auf Ihre Zufriedenheit.</span>  Dabei erledigen wir für Sie alle Planungen-
                    und Gestaltungsaufgaben und realisieren mit qualifizierten Kooperationspartnern Ihre Vorgaben und Ideen.
                </p>

        </div>
    </div>

    <!-- MOBILE -->
    <div class="san-bg-mobile">
        <img src="../assets/san2-.jpeg" alt="">
            <div class="sanierenText-mobile" >
                <h3 v-intersection>
                    Sanieren oder Renovieren?
                </h3><br>
                <p v-intersection>Es wird Zeit für eine Veränderung?
                    Das Parkett benötigt eine Aufbereitung oder Sie wünschen Ihre Räume in ganz neuem Licht zu sehen?
                    Vielleicht mit abgehangenen Decken und ein paar dimmbaren Spots?
                    <span>Wir übernehmen bereits ab der Planung und beraten Sie gerne bei der Auswahl von Design und Material.</span></p>
                    <br>
                <p v-intersection>
                    Mit gutem Grund sind unsere Kunden zufrieden: Wir verarbeiten ausschließlich hochwertige Materialien und garantieren Ihnen als Bauherr*in,
                    durch unsere besonders sorgfältige Bauausführung, eine exzellente Qualität.
                    Ihnen bieten wir maßgeschneiderte Lösungen zu vernünftigen Preisen.<span>In sämtlichen Phasen der Planung und Realisierung legen wir von Schönhaus Bau größten Wert auf Ihre Zufriedenheit.</span>  Dabei erledigen wir für Sie alle Planungs-
                    und Gestaltungsaufgaben und realisieren mit qualifizierten Kooperationspartnern Ihre Vorgaben und Ideen.
                </p>

        </div>
    </div>
</div>
</template>


<script>
import { onMounted } from 'vue';



export default {

    setup() {

    },
}
</script>

<style scoped>
/* BIG SCREEN SIZE */
.san-bg-mobile,.sanierenText-mobile{
    display:none;
}
.san-bg{
    padding-top:7%;
    padding-bottom:7%;
    position:relative;
}
h3{
    font-size:35px;
    color: #2c3e50;
}
img{
    background: rgba(255, 255, 255, 0.95);
    max-height:750px;
    width:100%;
    opacity: .3;

}
.sanierenText{
    /* background-color: rgba(255, 255, 255, 0.8); */
    font-size:18px;
    text-align: left;
    position: absolute;
    top:25%;
    left:20%;
    width:55%;
    border-radius: 2%;
    padding:10px;
    opacity:1;
    font-weight:500;
}


/* LAPTOP VIEW */
@media only screen and (max-width: 1600px) {
.san-bg{
    position:relative;
}
h3{
    font-size:35px;
    color: #2c3e50;
}

img{
    background: rgba(255, 255, 255, 0.95);
    max-height:650px;
    width:100%;
    opacity: .3;
}
.sanierenText{

    font-size:18px;
    text-align: left;
    position: absolute;
    top:20%;
    left:20%;
    width:55%;
    border-radius: 2%;
    padding:10px;
    opacity:1;
    font-weight:500;
}
}
@media only screen and (max-width: 1298px){
    img{
        max-height:600px;
    }
    .sanierenText{
    position:absolute;
    text-align: left;
    top:20%;
    left:20%;
    width:55%;
    border-radius: 2%;
    padding:10px;
    font-size:15px;
}

}
/* MOBILE */
@media only screen and (max-width: 767.98px){
    h3{
    font-size:30px;
    color: #2c3e50;
}


    img{
        background: rgba(255, 255, 255, 0.9);
        height:100%;
        width:100%;
        opacity: .3;
        position: absolute;
        left:0;
}
.sanierenText-mobile{
    display:block;
    text-align: left;
    width:90%;
    left:0;
    top:0;
    font-size:15px;
    padding:10px;
    

}
.san-bg-mobile{
    padding-top:20%;
    display:block;
    position: relative;

}
.san-bg,.sanierenText{
    display:none
}

}


</style>
