<template>
  <!--STARTBILD-->
  <transition name="slide-fade" mode="out-in" >
    <div class="carousel-items" v-show="currentSlide === index">
      <img :src="slide.url" alt="" loading="eager"/>
    </div>
  </transition>
</template>

<script>
export default {
  props: ["slide", "currentSlide", "index"],
};
</script>

<style scoped>

.carousel-items {
  width: 100%;
  height: 100%;
}
img {
  height: 100%;
  width: 100%;
  position: absolute;
  left:0;
  top:0;
}

.slide-fade-enter-active , .slide-fade-leave-active {
  transition: opacity .6s ease;
}
.slide-fade-enter {
  opacity: 0;

}
.slade-fade-leave-to {
   opacity: 0;
}

@media only screen and (max-width: 578px){
   img{
    max-height:51%;
    position: absolute;
    left:0;
    top:0;

  }
}


</style>
