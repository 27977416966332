<template>
    <div class="dsgvo" >
    <div class="dsgvo-content">
        <h2>Datenschutz Grundverordnung</h2><br><br>
        <p>1. Verwendung personenbezogener Daten <br> <br>

                Personenbezogene Daten werden von uns nur dann und nur in dem Umfang erhoben, wie Sie sie uns mit Ihrer Kenntnis selbst zur Verfügung stellen. Insbesondere erfolgt eine Nutzung dieser personenbezogenen Daten für Zwecke der Werbung/Marktforschung sowie zur Gestaltung unserer Dienste nur, wenn Sie uns ausdrücklich hierzu Ihre Einwilligung erteilt haben.

                Sie haben jederzeit ein Widerrufsrecht hinsichtlich einer erteilten Einwilligung.

                Eine Angabe der Verarbeitungszwecke erfolgt an den entsprechenden Stellen unserer Websites und unterliegt keiner nachträglichen Änderung. Eine Weitergabe der Daten an Dritte erfolgt ohne Ihre Einwilligung nur dann, wenn wir hierzu gesetzlich verpflichtet sind.
                <br><br>

                2. Auskunftsrecht <br><br>

                Sie haben das Recht, jederzeit Auskunft über die zu Ihrer Person gespeicherten Daten zu erhalten, einschließlich Herkunft und Empfänger Ihrer Daten sowie den Zweck der Datenverarbeitung. Ihre Anfrage richten Sie bitte schriftlich oder per E-Mail an unsere unten genannten Adressen.

                Diese Datenschutzerklärung gilt nur für Inhalte auf unseren Servern und umfasst nicht die auf unserer Seite verlinkten Webseiten. <br> <br>

                3. Über uns <br><br>

                Dies ist die Datenschutzerklärung der Firma Schoenhaus Bau GmbH, Waldstraße 1, 60528 Frankfurt am Main. Sollten Sie Fragen zu dieser Datenschutzerklärung haben, wenden Sie sich gerne direkt per Post oder E-Mail an uns.
            </p> <br> <br>

            <div class="pics">
                    <h2 class="logo-text" >Bilder genutzt von:</h2>
                    <a href='https://de.freepik.com/fotos/beratung'>Beratung Foto erstellt von prostooleh - de.freepik.com</a><br>
                    <a href='https://de.freepik.com/fotos/bau'>Bau Foto erstellt von freepik - de.freepik.com</a><br>
                    <a href='https://de.freepik.com/fotos/blaupause'>Blaupause Foto erstellt von freepik - de.freepik.com</a>
                </div>

        </div>
</div>
    </template>

    <script>
        export default {
    name: 'Datenschutz',
    data: () => {
        return {
            showDS: false,
        }
    },
  }
    </script>


    <style scoped>
    .dsgvo{
        width:50%;
        margin: 0 auto;
        padding-top:10%;
        padding-bottom:5%;
    }
    .dsgvo-content{
        text-align: left;
        color:black;
    }

    @media screen and (max-width: 578px){
        .dsgvo{
        width:65%;
        margin: 0 auto;
        padding-top:10%;
        padding-bottom:5%;
    }

    }


    </style>