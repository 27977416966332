<template>
  <div class="kontakt" id="kontakt">
    <div class="kontaktText">
      <h3 v-intersection>Besprechen Sie unverbindlich Ihr Bauprojekt mit uns!</h3>
      <br />
      <h4 v-intersection>
        Gerne beraten wir Sie und erarbeiten maßgeschneiderte, nachhaltige
        Lösungen für Ihr persönliches Bauprojekt. Mit Qualität, Kompetenz und
        Zuverlässigkeit zu Ihrer Traumimmobilie! - Schönhaus Bau!
      </h4>
    </div>
    <div class="kontaktForm">
      <form ref="form" method="POST">
        <label for="fname">Name</label>
        <input type="text" id="fname" placeholder="Vorname" name="fromName" />

        <label for="emil">E-Mail</label>
        <input
          type="email"
          id="email"
          placeholder="E-Mail Adresse"
          name="email"
        />

        <label for="subject">Nachricht</label>
        <textarea
          id="subject"
          placeholder="Verfasse eine Nachricht..."
          style="height: 200px"
          name="message"
        ></textarea>

        <input type="submit" value="Senden" @click.prevent="sendEmail" />
      </form>
    </div>
  </div>
</template>

<script>
import emailjs from '@emailjs/browser';

const serviceID= process.env.VUE_APP_SERVICE_ID;
const templateID= process.env.VUE_APP_TEMPLATE_ID;
const pubKEY= process.env.VUE_APP_PUBLIC_KEY;
export default {
  data: () => ({


  }),
  methods: {
    //for sending email
    sendEmail() {
      emailjs.sendForm(serviceID, templateID, this.$refs.form,pubKEY)
        .then((result) => {
          alert("Ihre Email wurde versendet!")
            console.log('SUCCESS!', result.text);
        }, (error) => {
            console.log('FAILED...', error.text);
        });
    }
  },
};
</script>

<style scoped>
h3{
  font-size:35px;
}
input[type="text"],
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}
input[type="email"]{
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

/* Style the submit button with a specific background color etc */
input[type="submit"] {
  background-color: rgb(4, 4, 63);
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* When moving the mouse over the submit button, add a darker green color */
input[type="submit"]:hover {
  background-color: #454ba0;
}
.kontaktForm {
  margin-top: 5%;
  padding-bottom: 10%;
  width: 50%;
  margin-left: 20%;
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
  text-align: left;
}
.kontaktText {
  text-align: left;
  width: 50%;
  margin-left: 20%;
  margin-top: 10%;
}
.kontakt {
  padding-top:5%;
  border-bottom: 1px solid rgb(4, 4, 63);
  padding-bottom: 10%;
}
@media only screen and (max-width: 1298px){
  .kontaktForm {

  padding-bottom: 10%;
  width: 80%;
  margin:0 auto;
  border-radius: 5px;
  background-color: #f2f2f2;
  text-align: left;

}
  .kontaktText {
    font-size:13px;
    text-align: left;
}
}
/* MOBILE */
@media only screen and (max-width: 578px){

  h3{
    font-size:30px;
  }
  input[type="text"],
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}
input[type="email"]{
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

/* Style the submit button with a specific background color etc */
input[type="submit"] {
  background-color: rgb(4, 4, 63);
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* When moving the mouse over the submit button, add a darker green color */
input[type="submit"]:hover {
  background-color: #454ba0;
}
.kontaktForm {

  padding-bottom: 10%;
  width: 80%;
  margin:0 auto;
  border-radius: 5px;
  background-color: #f2f2f2;
  text-align: left;

}
.kontaktText {
  text-align:left;
  width: 80%;
  padding-top: 15%;
  margin:0 auto;
}
.kontakt {
  border-bottom: 1px solid rgb(4, 4, 63);
  padding-bottom: 10%;


}
h3{
  text-align: center;

}

}

</style>
